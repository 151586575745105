<template>
    <div>
        <a-drawer wrapClassName="drawer-custom" placement="bottom" :height="'89%'" :closable="false"  :visible="visible">
            <div class="flex justify-end items-center">
                <div class="flex items-center">
                    <a href="javascript:void(0)" v-if="this.uuids.length > 0" @click="clearHistory()" class="block hover:bg-gray-200 rounded-full bg-gray-100 px-2 p-1 flex items-center hover:bg-gray-300">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 3c-4.97 0-9 4.03-9 9H1l4 3.99L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"/></svg>
                        <span class="block ml-1 text-xs text-gray-800">{{ $root.getKeyWord('clear_history') }}</span>
                    </a>
                    <a href="javascript:void(0)" @click="toggleMyOrders()" class="block hover:bg-gray-200 rounded-full p-1">
                        <svg xmlns='http://www.w3.org/2000/svg' class='fill-current text-gray-800 rounded-full' viewBox='0 0 512 512'  height="32" width="32"><title>Close</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M368 368L144 144M368 144L144 368'/></svg>
                    </a>
                </div>
            </div>
            <div>
                <div  v-if="Object.keys(orders).length > 1">
                    <div class="mt-4" v-if="orders.draft.length > 0">
                        <div class="rounded  leading-none flex items-center mt-2 text-xl">
                            <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="text-yellow-500  fill-current w-6 h-6"><path d="M255.8 48C141 48 48 141.2 48 256s93 208 207.8 208c115 0 208.2-93.2 208.2-208S370.8 48 255.8 48zm.2 374.4c-91.9 0-166.4-74.5-166.4-166.4S164.1 89.6 256 89.6 422.4 164.1 422.4 256 347.9 422.4 256 422.4z"></path><path d="M266.4 152h-31.2v124.8l109.2 65.5 15.6-25.6-93.6-55.5V152z"></path></svg></span>
                            {{ $root.getKeyWord('draft_orders') }}
                        </div>
                        <notification-order :orders="orders.draft" :order-type="'draft'"></notification-order>
                    </div>

                    <div class="mt-4" v-if="orders.pending.length > 0">
                        <div class="rounded  leading-none flex items-center mt-2 text-xl">
                            <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="text-green-500  fill-current w-6 h-6"><path d="M255.8 48C141 48 48 141.2 48 256s93 208 207.8 208c115 0 208.2-93.2 208.2-208S370.8 48 255.8 48zm.2 374.4c-91.9 0-166.4-74.5-166.4-166.4S164.1 89.6 256 89.6 422.4 164.1 422.4 256 347.9 422.4 256 422.4z"></path><path d="M266.4 152h-31.2v124.8l109.2 65.5 15.6-25.6-93.6-55.5V152z"></path></svg></span>
                            {{ $root.getKeyWord('pending_orders') }}
                        </div>
                        <notification-order :orders="orders.pending"  :order-type="'pending'"></notification-order>
                    </div>

                    <div class="mt-8" v-if="orders.histories.length > 0">
                        <div class="flex justify-between">
                            <div class="rounded leading-none flex items-center mt-2 text-xl">
                                <!--<span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="fill-current text-gray-600 w-6 h-6"><path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm106.5 150.5L228.8 332.8h-.1c-1.7 1.7-6.3 5.5-11.6 5.5-3.8 0-8.1-2.1-11.7-5.7l-56-56c-1.6-1.6-1.6-4.1 0-5.7l17.8-17.8c.8-.8 1.8-1.2 2.8-1.2 1 0 2 .4 2.8 1.2l44.4 44.4 122-122.9c.8-.8 1.8-1.2 2.8-1.2 1.1 0 2.1.4 2.8 1.2l17.5 18.1c1.8 1.7 1.8 4.2.2 5.8z"></path></svg></span>-->
                                {{ $root.getKeyWord('ready_orders') }}
                            </div>
                        </div>
                        <notification-order :orders="orders.histories" :order-type="'histories'"></notification-order>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-40 w-full flex flex-col items-center">
                        <img class="w-48 h-48"  :src="require(`@/assets/image/noorders.png`)">
                        <div class="text-gray-600 text-lg my-10">{{ $root.getKeyWord('no_orders_status') }}</div>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import Pusher from 'pusher-js';
import { eventBus } from '../../../main';
import notificationOrder from './ArticleNotificationOrder.vue';
import { ref, set, onValue, onChildAdded } from 'firebase/database';
import { db } from '../../../firebase'; // Adjust the path as needed

export default {
    components: { notificationOrder },

    data() {
        return {
            visible: false,
            orders: [],
            show_ready_orders: false,
            show_rejected_orders: false,
            uuids: localStorage.getItem('fs_uuids') && localStorage.getItem('fs_uuids') !== undefined ? JSON.parse(localStorage.getItem('fs_uuids')) : [],
            subscribedHotelUuid: null,
        }
    },

    created() {
        eventBus.$on('toggle-my-orders', data => {
            return this.toggleMyOrders();
        });

        eventBus.$on('get-orders', data => {
            this.getOrders();
        });

        //this.subscribe();
        this.subscribeFirebase();
    },

    watch: {
        visible(val) {
            this.$root.freezeActivePageScroll(val);
        }
    },

    mounted() {
       this.fetch();
    },

    methods: {
        toggleMyOrders() {
            this.visible = !this.visible;
            eventBus.$emit('active-bottom-navigation', {visible: this.visible, menu: 'my-orders'});
            if (this.visible) {
                this.uuids = localStorage.getItem('fs_uuids') && localStorage.getItem('fs_uuids') !== undefined ? JSON.parse(localStorage.getItem('fs_uuids')) : [];
                this.fetch();
                if (this.uuids.length > 0) {
                    setTimeout(() => this.fetch(), 5000);
                    setTimeout(() => this.fetch(), 10000);
                    setTimeout(() => this.fetch(), 15000);
                }
            }

            return this.visible;
        },
        
        getOrders(){
          this.uuids = localStorage.getItem('fs_uuids') && localStorage.getItem('fs_uuids') !== undefined ? JSON.parse(localStorage.getItem('fs_uuids')) : [];
          this.fetch()
        },

        fetch() {
            if (this.uuids.length == 0){
              eventBus.$emit('badges-order-bottom-navigation',{
                menu: 'my-orders',
                count: 0,
              });
              return false;
            }
            this.$http.get(process.env.VUE_APP_API_URL + 'hotels/' + this.$root.hotelCode + '/orders/check', {
                    params:{
                        order_uuids: this.uuids,
                    }
                }).then(response => {
                    if (Object.keys(response.data).length > 2) {
                        let count = 0
                        if(this.orders.pending && (this.orders.pending.length !== response.data.pending.length)){
                            count += this.orders.pending.length;
                        }
                        else if (this.orders.pending){
                            this.orders.pending.map(function (item, index) {
                                if (item.note_kit && item.note_kit.notes.length !== response.data.pending[index].note_kit.notes.length) {
                                    count += response.data.pending.length;
                                }
                                else if (!item.note_kit && response.data.pending[index].note_kit) {
                                    count += response.data.pending.length;
                                }
                            });
                        }
                        if(this.orders.draft && (this.orders.draft.length !== response.data.draft.length)){
                            count += this.orders.draft.length
                        }
                        else if (this.orders.draft){
                            this.orders.draft.map(function (item, index){
                                if (item.note_kit && item.note_kit.notes.length !== response.data.draft[index].note_kit.notes.length) {
                                    count += response.data.draft.length;
                                }
                                else if (!item.note_kit && response.data.draft[index].note_kit) {
                                    count += response.data.draft.length;
                                }
                            });
                        }
                        this.orders = response.data;
                        if (count !== 0 && this.visible === false) {
                            eventBus.$emit('badges-order-bottom-navigation', {
                                menu: 'my-orders',
                                count: count
                            });
                        }
                    }
                });
        },

        opened() {
            if (document.querySelector('.vm--modal')) {
                document.querySelector('.vm--modal').style.top = '0px';
            }
        },

        clearHistory() {
            if (confirm(this.$root.getKeyWord('clear_history') + '?') == true) {
                this.$nextTick(() => {
                    this.uuids = [];
                    this.orders = [];
                    localStorage.setItem('fs_uuids',  JSON.stringify(this.uuids));
                    eventBus.$emit('badges-order-bottom-navigation',{
                      menu: 'my-orders',
                      count: 0,
                    });
                });
            }
        },

        /*subscribe () {
            var key = process.env.VUE_APP_PUSHER_KEY;
            var cluster = process.env.VUE_APP_PUSHER_CLUSTER;
            let pusher = new Pusher(key, { cluster: cluster });

            pusher.subscribe('hotel-websocket-event.' + this.$root.hotel.uuid).bind('hotelWebsocketEvent', data => {
                if ((data.code == 'order-created' || data.code == 'order-updated') && this.uuids.indexOf(data.data.order_uuid) > -1) {
                  this.fetch();
                }
            });
        },*/

        subscribeFirebase() {
            console.log('uuid', this.$root.hotel.uuid);
            if (this.$root.hotel && this.$root.hotel.uuid === this.subscribedHotelUuid) {
                // Already subscribed to this chat
                console.log('Already subscribed to this order:', this.$root.hotel.uuid);
                return;
            }
            
            const serverPrefix = process.env.VUE_APP_SERVER.toLowerCase();
            const channel = `channels/${serverPrefix}/hotels/${this.$root.hotel.uuid}/messages`;
            const messagesRef = ref(db, channel);
            
            this.subscribedHotelUuid = this.$root.hotel.uuid; 

            onValue(messagesRef, () => {
                this.isInitialized = true; // Mark the listener as initialized
                console.log('Subscribed to channel ' + channel);
            }, { onlyOnce: true });

            onChildAdded(messagesRef, (snapshot) => {
                if (!this.isInitialized) return;

                const data = snapshot.val();
                if (data) {
                    if ((data.type == 'order-created' || data.type == 'order-updated') && this.uuids.indexOf(data.order_uuid) > -1) {
                        this.fetch();
                    }
                }
            });
        },        
    }
}
</script>